.slick-next {
    right: 10.5%;
    top: 507px;
   
}

@media(max-width: 1820px) {
    .slick-next {
        right: 8.2%;
    }
}

@media(max-width: 1700px) {
    .slick-next {
        right: 7.2%;
    }
}

@media(max-width: 1400px) {
    .slick-next {
        left: 27.8%;
        top: 455px;
    }
}

@media(max-width: 1146px) {
    .slick-next {
        left: 91%;
        top: 620px;
    }
}

@media(max-width: 580px) {
    .slick-next {
        top: 74px;
    }
}



.slick-next:hover::after, .slick-prev:hover::after {
    background-color: black;
}

.slick-prev {
    z-index: 5;
    left: 47%;
    top: 507px;
}

@media(max-width: 1600px) {
    .slick-prev {
        left: 40%;
    }
}

@media(max-width: 1496px) {
    .slick-prev {
        left: 42%;
    }
}

@media(max-width: 1400px) {
    .slick-prev {
        left: 1.5%;
        top: 455px;
    }
}

@media(max-width: 1298px) {
    .slick-prev {
        left: 0.5%;
        top: 455px;
    }
}

@media(max-width: 1146px) {
    .slick-prev {
        left: 5%;
        top: 620px;
    }
}

@media(max-width: 580px) {
    .slick-prev {
        left: 2%;
        top: 75px;
    }
}





.slick-next:before, .slick-prev:before {
    
}

.slick-next:before {
    content: '→';
    font-size: 70px;
}

.slick-next, .slick-prev {

}

.slick-prev:before {
    font-size: 70px;
}

@media(max-width: 1146px) {
    .slick-prev:before {
        font-size: 30px;
    }
}

@media(max-width: 1146px) {
    .slick-next:before {
        font-size: 30px;
    }
}


